import parse from "html-react-parser";

export function breakpointArray(before, after, breakpoint) {
  return Array(breakpoint + 1)
    .fill()
    .map((_, i) => (i == breakpoint ? after : before));
}

export function parseWithFallback(html, fallback = "") {
  if (typeof html === 'string') {
    return parse(html)
  } else {
    return fallback
  }
}