import React, { Fragment } from "react";
import { IconButton } from "theme-ui";
import useMeasure from "react-use-measure";
import Link from "./util/GatsbyLink";

/** @jsx jsx */
import { jsx, css } from "theme-ui";

export const TitleBoxHeights = {
  homeHeights: ["50vh"],
  contentPageHeights: ["10vh"],
};

export default function TitleBox({
  image = "/giano-front.jpg",
  children,
  heights = TitleBoxHeights.homeHeights,
  icons,
}) {
  const [ref, { height, top }] = useMeasure();
  return (
    <Fragment>
      <div
        sx={(theme) => ({
          backgroundImage:
            "linear-gradient(rgba(0, 47, 167, 1),rgba(0, 47, 167, 0.3)),url(" +
            image +
            ")",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: heights,
          display: "flex",
          flexDirection: ["column", "column", "row"],
          justifyContent: "center",
          alignItems: "center",
        })}
        ref={ref}
      >
        {children}
      </div>
      {icons && (
        <div
          sx={(theme) => ({
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            width: "100%",
            height: height,
            position: "relative",
            top: height * -1,
            marginBottom: height * -1,
          })}
        >
          {icons.map((e, i) => (
            <Link to={e.navigate} key={i}>
              <IconButton
                sx={{
                  height: ["46px", "54px"],
                  width: "auto",
                  color: "white",
                }}
              >
                {React.cloneElement(e.icon, {
                  style: { height: "100%", width: "auto" },
                })}
              </IconButton>
            </Link>
          ))}
        </div>
      )}
    </Fragment>
  );
}
