import React, { Component, Fragment } from "react";
import { IconButton } from "theme-ui";
import useMeasure from "react-use-measure";
import { Link } from "gatsby";

/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";

export default function GatsbyLink({ to, children }) {
  const internal = /^\/(?!\/)/.test(to);
  const style = {
    color: "inherit",
    textDecorationColor: "white",
    textDecoration: "inherit",
  };
  if (internal) {
    return (
      <Link to={to} sx={style}>
        {children}
      </Link>
    );
  } else {
    return (
      <a href={to} sx={style}>
        {children}
      </a>
    );
  }
}
