import React, { Component, Fragment } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { IconButton, Button } from "theme-ui";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "-!svg-react-loader?classIdPrefix=logonav-!../../assets/logoGiano.svg";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import EventIcon from "@material-ui/icons/Event";
import LinkIcon from "@material-ui/icons/Link";
import { FaSyringe, FaBookMedical } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";
import { AiFillNotification } from "react-icons/ai";
import { IconContext } from "react-icons";
import Link from "./util/GatsbyLink";

import { navigate } from "gatsby";

/** @jsx jsx */
import { jsx } from "theme-ui";

const primaryMenuElements = [
  {
    name: "Ambiti Clinici",
    icon: <FaBookMedical />,
    navigate: "/ambiti",
  },
  {
    name: "Servizi",
    icon: <LocalHospitalIcon />,
    navigate: "/servizi",
  },
  {
    name: "Medici",
    icon: <PeopleAltIcon />,
    navigate: "/medici",
  },
  {
    name: "Eventi",
    icon: <EventIcon />,
    navigate: "/eventi",
  },
];

const otherMenuElements = [
  {
    name: "News",
    icon: <AiFillNotification />,
    navigate: "/news",
  },
  /*{
    name: "Prestazioni",
    icon: <FaSyringe />,
    navigate: "/prestazioni",
  },*/
  {
    name: "Link",
    icon: <LinkIcon />,
    navigate: "/link",
  },
];

function ButtonAppBarCollapse(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Fragment>
      <Button
        key="collapse"
        color="inherit"
        onClick={handleMenu}
        sx={{
          display: ["none", "none", "block"],
        }}
      >
        Altro
      </Button>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {props.children}
      </Menu>
    </Fragment>
  );
}

function ListItemCollapse({
  children,
  name = "Altro",
  icon = <FiMoreHorizontal />,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <Fragment>
      <ListItem button onClick={handleClick} key={"collapse"}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {React.Children.toArray(children).map((c) =>
            React.cloneElement(c, {
              style: { paddingLeft: "30px" },
            })
          )}
        </List>
      </Collapse>
    </Fragment>
  );
}

export default function NavBar() {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
  };

  return (
    <IconContext.Provider value={{ className: "MuiSvgIcon-root" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "primary",
          transform: "unset",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              minHeight: ["46px", "54px"],
              width: "auto",
              marginRight: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <style>
              {` .logonav-cls-1{fill:white !important;}.logonav-cls-2{stroke:white !important;}`}
            </style>
            <Logo
              sx={{
                width: "auto",
                height: "100%",
              }}
            />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontSize: 14,
              flexGrow: 1,
              height: "100%",
            }}
          >
            Poliambulatorio Giano
          </Typography>
          {primaryMenuElements.map(({ name, navigate }, i) => (
            <Link to={navigate} key={i}>
              <Button
                key={name}
                color="inherit"
                sx={{
                  display: ["none", "none", "block"],
                }}
              >
                {name}
              </Button>
            </Link>
          ))}
          <ButtonAppBarCollapse>
            {otherMenuElements.map(({ name, navigate }, i) => (
              <Link to={navigate} key={i}>
                <MenuItem key={name}>{name}</MenuItem>
              </Link>
            ))}
          </ButtonAppBarCollapse>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              display: ["block", "block", "none"],
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <div
          role="presentation"
          onKeyDown={toggleDrawer(false)}
          sx={{
            width: 250,
          }}
        >
          <List>
            {primaryMenuElements.map(({ name, icon, navigate }, i) => (
              <Link to={navigate} key={i}>
                <ListItem button key={name} onClick={toggleDrawer(false)}>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={name} />
                </ListItem>
              </Link>
            ))}
            <ListItemCollapse>
              {otherMenuElements.map(({ name, icon, navigate }, i) => (
                <Link to={navigate} key={i}>
                  <ListItem button key={name} onClick={toggleDrawer(false)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={name} />
                  </ListItem>
                </Link>
              ))}
            </ListItemCollapse>
          </List>
        </div>
      </Drawer>
    </IconContext.Provider>
  );
}
