import React, { Component, Fragment } from "react";
import Link from "./util/GatsbyLink";
import { breakpointArray } from "./util/Functions";
import { useStaticQuery, graphql } from "gatsby";

/** @jsx jsx */
import { jsx, css, Text } from "theme-ui";

function FooterGroup({
  children,
  maxWidth = "10vw",
  minWidth = "5vw",
  order,
  breakpoint = 1,
}) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        //flexGrow: "1",
        maxWidth: maxWidth,
        minWidth: minWidth,
        paddingTop: "8px",
        paddingLeft: breakpointArray("0px", "8px", breakpoint),
        paddingRight: breakpointArray("0px", "8px", breakpoint),
        paddingBottom: "8px",
        order: order,
      }}
    >
      {children}
    </div>
  );
}

function FooterGroupHeader({ children }) {
  return (
    <Fragment>
      <Text variant="footerHeader">{children}</Text>
      <hr
        sx={{
          width: "100%",
          marginTop: 0,
          marginLeft: 0,
          marginRight: 0,
        }}
      />
    </Fragment>
  );
}

function FooterLink({ children, to, underline = true }) {
  return (
    <Link to={to}>
      <Text
        variant="footer"
        sx={{
          "&:hover": {
            textDecoration: underline ? "underline" : "none",
          },
        }}
      >
        {children}
      </Text>
    </Link>
  );
}

export function Footer({ children, breakpoint = 1 }) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        bg: "primary",
        paddingTop: "16px",
        paddingLeft: breakpointArray("0px", "16px", breakpoint),
        paddingRight: breakpointArray("0px", "16px", breakpoint),
        paddingBottom: "16px",
        width: "100%",
        alignItems: "center",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: breakpointArray("column", "row", breakpoint),
          paddingBottom: "16px",
          width: "100%",
          justifyContent: "center",
          alignItems: breakpointArray("center", "flex-start", breakpoint),
        }}
      >
        {children}
      </div>
    </div>
  );
}

export default function GianoFooter() {
  var categories = useStaticQuery(graphql`
    query {
      keystone {
        allLinkCategories(sortBy: name_ASC, where: { footerShow: true }) {
          name
          links(sortBy: title_ASC, where: { footerShow: true }) {
            title
            link
            description
            coverImage {
              publicUrl
            }
          }
        }
      }
    }
  `).keystone.allLinkCategories;
  const groupWidths = breakpointArray("80vw", "22vw", 1);
  const groupMinWidths = breakpointArray("80vw", "8vw", 1);
  return (
    <Footer>
      <FooterGroup maxWidth={groupWidths} minWidth={groupMinWidths}>
        <FooterGroupHeader>Poliambulatorio Giano</FooterGroupHeader>
        <Text variant="footer">
          Poliambulatorio Giano - Via Ancona, 290 - 47522 Cesena (FC)
          <br />
          Tel. 393.9021487
          <br />
          (8:30-20 lun-sab)
          <br />
          info@poliambulatoriogiano.it
        </Text>
      </FooterGroup>
      {categories.map((c, i) => (
        <FooterGroup maxWidth={groupWidths} minWidth={groupMinWidths} key={i}>
          <FooterGroupHeader>{c.name}</FooterGroupHeader>
          {c.links.map((l, j) => (
            <FooterLink to={l.link} key={j}>
              {l.title}
            </FooterLink>
          ))}
        </FooterGroup>
      ))}
    </Footer>
  );
}
